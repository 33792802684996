.results-container {
    position: relative;
}

.loader-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: left;
    height: 100vh;
    padding: 20px;
    text-align: center;
}

.content-list {
    font-size: 1.1em;
    text-align: justify;
    list-style: inside;
}

.loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    width: 100%;
}

.buttons-container {
    margin-top: 30px;
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.results-button {
    width: 130px;
    margin-bottom: 5px;
    display: inline-block;
    padding: 10px 20px;
    background-color: #ffffff;
    color: #080000;
    text-decoration: none;
    align-items: left;
    border-radius: 0px;
    border: 1px solid black;
}

.container {
    margin: 0 auto;
    text-align: center;
}

.results-main {
    margin-top: 60px;
    position: absolute;
    left: 49%;
    display: flex;
    flex-direction: column;
    justify-content: center;

}

.filter-input {
    text-align: left;
    display: flex;
    flex-direction: row;
    font-size: 18px;
    width: 850px;
    padding: 10px 20px;
    background-color: #fafafa;
    color: #080000;
    text-decoration: none;
    border-radius: 5%;
    border: 0.5px solid rgb(233, 233, 233);
}

.checkbox-column {
    display: flex;
    flex-direction: column;
    width: 50%;
}

.checkbox-column {
    display: flex;
    flex-direction: column;
    width: 50%;
}

.results-main2 {
    margin-top: 300px;
    position: absolute;
    left: 49%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 800px;
}

.chart-container {
    width: 800px;
}

.container-text1 {
    text-align: justify;
    display: flex;
    flex-direction: column;
    justify-content: top;
    align-items: left;
    margin-right: 55%;
    background-color: #fff;
    padding: 30px;
    margin-top: 30px;
    border-radius: 15px;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
    width: 45%;
    box-sizing: border-box;
    overflow: auto;
    max-height: 80vh;
    border: 0.5px solid rgb(233, 236, 236)
}

.results-main1 {
    display: flex;
    flex-direction: column;
    align-items: center;

    justify-content: top;
    width: 100;
}

.results-table {
    align-items: center;
    border-collapse: collapse;
    margin-top: 20px;
    font-size: 14px
}

.results-table th,
.results-table td {
    border: 1px solid black;
    padding: 10px;
    text-align: left;
}

@media (max-width: 1000px) {
    .buttons-container1 {
        margin-top: 0px;
        margin-right: 5%;
    }

    .chatbot-container button[type="submit"],
    .chatbot-container button[type="button"] {
        width: 80px;
    }

    .results-button {
        max-width: 100px !important;
        margin-bottom: 5px !important;
        display: inline-block !important;
        padding: 10px 20px !important;
    }

    .chatbot-container {
        width: 90% !important;
    }

    .buttons-container {
        margin-top: 0px;
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: row-reverse;
        justify-content: center;
    }
}