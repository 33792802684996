.chatbot-container1 {
    margin-top: 1%;
    position: relative;
}

.buttons-container1 {
    margin-top: 15px;
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.results-button {
    width: 130px;
    margin-bottom: 5px;
    display: inline-block;
    padding: 10px 20px;
    background-color: #ffffff;
    color: #080000;
    text-decoration: none;
    align-items: left;
    border-radius: 0px;
    border: 1px solid black;
}

.chatbot-container {
    width: 70%;
    margin: auto;
    border: 1px solid #000000;
    padding: 20px;
    border-radius: 0px;
}

.chatbot-header {
    text-align: center;
    font-size: 2em;
    margin-bottom: 20px;
}

.chat-window {
    height: 500px;
    border: 1px solid #7d7d7d;
    padding: 10px;
    border-radius: 3px;
    overflow-y: auto;
    margin-bottom: 20px;
}

.message-sender {
    font-weight: bold;
}

.chat-input-area {
    display: flex;
    justify-content: space-between;
}

.chat-input {
    flex-grow: 1;
    margin-right: 10px;
}

.chatbot-container button[type="submit"],
.chatbot-container button[type="button"] {
    display: inline-block;
    width: 100px;
    height: 36px;
    padding: 10px 20px;
    background-color: #fafafa;
    color: #080000;
    text-decoration: none;
    border-radius: 0px;
    border: 1px solid black;
}

.chatbot-container button[type="submit"]:hover,
.chatbot-container button[type="button"]:hover {
    background-color: #c1d2e5;
}

.chatbot-container input[type="text"] {
    height: 30px;
    background-color: #f5faff;
}

@media (max-width: 1000px) {
    .buttons-container1 {
        margin-top: 0px;
        margin-right: 5%;
    }

    .chatbot-container button[type="submit"],
    .chatbot-container button[type="button"] {
        width: 80px;
    }

    .results-button {
        max-width: 100px;
        margin-bottom: 5px;
        display: inline-block;
        padding: 10px 20px;
    }

    .chatbot-container {
        width: 90% !important;
    }

    .buttons-container {
        margin-top: 0px;
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: row-reverse;
        justify-content: center;
    }
}

@media (max-width: 1000px) {
    .buttons-container1 {
        margin-top: 0px;
        margin-right: 5%;
    }

    .chatbot-container button[type="submit"],
    .chatbot-container button[type="button"] {
        width: 80px;
    }

    .results-button {
        max-width: 100px !important;
        margin-bottom: 5px !important;
        display: inline-block !important;
        padding: 10px 20px !important;
    }

    .chatbot-container {
        width: 90% !important;
    }

    .buttons-container {
        margin-top: 0px;
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: row-reverse;
        justify-content: center;
    }
}