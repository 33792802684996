.results-container {
    position: relative;
}

.report1-container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;

}

.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 500px;
}

iframe {
    border: none;
    margin-top: 20px;
    display: block;
}