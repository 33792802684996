.container-upload input[type="date"],
.container-upload input[type="file"],
.container-upload input[type="text"] {
    max-width: 250px;
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 0px;
    background-color: rgb(255, 255, 255);
}


.container-upload-text {
    margin-bottom: 5px;
}

.container-upload button[type="submit"] {

    display: inline-block;
    margin-top: 15px;
    padding: 10px 20px;
    background-color: #fafafa;
    color: #080000;
    text-decoration: none;
    border-radius: 0px;
    font-size: large;
    border: 1px solid black;
}

.container-upload button[type="submit"]:hover {
    color: lightgrey;
}

.custom-margin {
    margin-bottom: 0.2rem !important;
    /* Important might be needed to override Bootstrap styles */
}