.dashboard {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.dashboard button[type="submit"] {
    display: inline-block;
    cursor: pointer;
    font-size: large;
    width: 200px;
    height: 36px;
    background-color: #f7f7f7;
    color: #070707;
    text-decoration: none;
    border-radius: 0px;
    border: 1px solid black;
    transition: background-color 0.3s
}

.filters {
    margin: 10px 0;
}

.filters select[type="school"],
.filters select[type="grade"] {
    cursor: pointer;
    display: inline-flex;
    width: 100px;
    height: 36px;
    background-color: #f7f7f7;
    color: #080000;
    text-decoration: none;
    border-radius: 0px;
    border: 1px solid black;
}

.student-list {
    max-height: 400px;
    overflow-y: auto;
    text-align: center;
}

.student-list div {
    padding: 10px;
    border-bottom: 1px solid #e1e1e1;
    cursor: pointer;
    transition: background-color 0.3s;
}

.student-list div:last-child {
    border-bottom: none;
}

.student-list div:hover {
    background-color: #f7f7f7;
}

.card-custom {
    box-shadow: 3px 3px 3px 3px rgba(174,174,174,0.5);
    border: none !important;
}


.list-group-item span {
    border: solid #222;
    border-width: 0 1px 1px 0;
    display: inline;
    cursor: pointer;
    padding: 3px;
    position: absolute;
    right: 0;
    margin-top: 10px;
}

.list-group-item a.btn.collapsed span {
    transform: rotate(40deg);
    -webkit-transform: rotate(40deg);
    transition: .3s transform ease-in-out;
}

.list-group-item a.btn span {
    transform: rotate(-140deg);
    -webkit-transform: rotate(-140deg);
    transition: .3s transform ease-in-out;
}